<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <v-hover
        v-slot="{ hover }"
        class="mb-8"
      >
        <v-card
          outlined
          :style="{ borderColor: hover ? 'black' : null }"
        >
          <v-subheader>
            Locations
          </v-subheader>
          <v-card-text class="pt-0">
            <m-grid-array
              v-model="value.items"
              item-photo="photo"
              item-title="name"
              item-subtitle="position"
              @edit-item="editItem($event)"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              outlined
              @click="itemDialog = true"
            >
              Add Team Member
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-card-text>
    <v-dialog
      v-model="itemDialog"
      max-width="600px"
      scrollable
    >
      <validation-observer
        v-if="itemDialog"
        ref="itemForm"
        v-slot="{ valid }"
        slim
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="itemDialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Name"
            >
              <v-text-field
                v-model="editedItem.name"
                outlined
                label="Name"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Position"
            >
              <v-text-field
                v-model="editedItem.position"
                outlined
                label="Position"
                required
                :error-messages="errors"
              />
            </validation-provider>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Photo
                </v-subheader>
                <v-card-text class="pt-0">
                  <asset-manager v-model="editedItem.photo" />
                </v-card-text>
              </v-card>
            </v-hover>
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Phones
                </v-subheader>
                <v-card-text class="pt-0">
                  <m-grid-array
                    v-model="editedItem.phones"
                    item-title="phone"
                    @edit-item="editItemPhone($event)"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    @click="itemDialogPhone = true"
                  >
                    Add Phone
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
            <v-text-field v-model="editedItem.fax" outlined label="Fax" />
            <v-text-field v-model="editedItem.email" outlined label="Email" />
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
      <v-dialog
        v-model="itemDialogPhone"
        max-width="480px"
        scrollable
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitlePhones }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="itemDialogPhone = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-2">
            <v-hover
              v-slot="{ hover }"
              class="mb-8"
            >
              <v-card
                outlined
                :style="{ borderColor: hover ? 'black' : null }"
              >
                <v-subheader>
                  Photo
                </v-subheader>
                <v-card-text class="pt-0">
                  <v-text-field v-model="editedItemPhone.phone" outlined label="Phone" />
                </v-card-text>
              </v-card>
            </v-hover>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="savePhone"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: 'MTeamCardsForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null,
    itemDialog: false,
    itemDialogPhone: false,
    editedIndex: -1,
    editedItem: {
      name: '',
      position: '',
      photo: '',
      phones: [],
      fax: '',
      email: ''
    },
    defaultItem: {
      name: '',
      position: '',
      photo: '',
      phones: [],
      fax: '',
      email: ''
    },
    editedIndexPhone: -1,
    editedItemPhone: {
      phone: ''
    },
    defaultItemPhone: {
      phone: ''
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Location' : 'Edit Location'
    },
    formTitlePhones() {
      return this.editedIndexPhone === -1 ? 'New Phone' : 'Edit Phone'
    }
  },
  watch: {
    itemDialog(val) {
      val || this.close()
    },
    itemDialogPhone(val) {
      val || this.closePhone()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.items = JSON.parse(JSON.stringify(this.value.items))
    },
    editItem(item) {
      this.editedIndex = this.value.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.itemDialog = true
    },
    deleteItem(item) {
      const index = this.value.items.indexOf(item)
      confirm('Are you sure you want to delete this item?') && this.value.items.splice(index, 1)
    },
    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.value.items[this.editedIndex], this.editedItem)
      } else {
        this.value.items = [...this.value.items, this.editedItem]
      }
      this.itemDialog = false
    },
    editItemPhone(item) {
      this.editedIndexPhone = this.editedItem.phones.indexOf(item)
      this.editedItemPhone = Object.assign({}, item)
      this.itemDialogPhone = true
    },
    closePhone() {
      setTimeout(() => {
        this.editedItemPhone = Object.assign({}, this.defaultItemPhone)
        this.editedIndexPhone = -1
      }, 300)
    },
    savePhone() {
      if (this.editedIndexPhone > -1) {
        Object.assign(this.editedItem.phones[this.editedIndexPhone], this.editedItemPhone)
      } else {
        this.editedItem.phones = [...this.editedItem.phones, this.editedItemPhone]
      }
      this.itemDialogPhone = false
    }
  }
}
</script>
